import "bootstrap/dist/css/bootstrap.min.css";
import Button from "react-bootstrap/Button";
import NavbarComponent from "../components/NavbarComponent";
import FooterComponent from "../components/FooterComponent";

const Home = () => {
  return (
    <div>
      <NavbarComponent />
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "20px",
          textAlign: "center",
        }}
      >
        <div>
          <h1 style={{ fontSize: "2rem" }}>
            Techie GPT: Your AI-Powered Assistance
          </h1>
          <p style={{ maxWidth: "600px", textAlign: "left" }}>
            Welcome to Techie GPT! Our innovative service provides you
            with fast and reliable support for all your technical questions and
            issues. Utilizing cutting-edge AI technology, we are available 24/7
            to help you find solutions, provide guidance, and answer your
            inquiries. Whether it's software problems, hardware questions, or
            general tech concerns, our goal is to offer you straightforward and
            effective assistance. Trust Techie GPT to help you overcome
            your technical challenges!
          </p>
        </div>
      </div>
      <Button variant="primary" href="authenticate" size="lg">
        Login
      </Button>{" "}
      <Button variant="primary" href="register" size="lg">
        Register
      </Button>{" "}
      <Button variant="success" href="/" size="lg">
        Chat
      </Button>{" "}
      <FooterComponent />
    </div>
  );
};

export default Home;
