import { useState } from "react";
import NavbarComponent from "../components/NavbarComponent";
import FooterComponent from "../components/FooterComponent";
import { useNavigate, Link } from "react-router-dom"; // Import Link

const Login = () => {
  const [email_adress, setEmail_Adress] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  
  const navigate = useNavigate(); // Initialize useNavigate

  const handleSubmit = async (e) => {
    e.preventDefault();

    const userdetails = { email_adress, password };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/api/userdetails/login`,
        {
          method: "POST",
          body: JSON.stringify(userdetails),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const json = await response.json();

      if (!response.ok) {
        setError(json.error || "Login failed. Wrong email or password."); // Display error message
      } else {
        setEmail_Adress("");
        setPassword("");
        setError(""); // Clear error message

        // Store the token in local storage
        localStorage.setItem("token", json.token);
        console.log("Logged in");
        navigate("/");
      }
    } catch (err) {
      setError("Something went wrong. Please try again."); // Catch fetch errors
    }
  };

  return (
    <div>
      <NavbarComponent />
      
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "20px",
        }}
      >
        <form
          className="border rounded p-4 shadow"
          onSubmit={handleSubmit}
          style={{ maxWidth: "400px", width: "100%" }}
        >
          <h3 className="text-center">Login</h3>

          <div className="mb-3">
            <label>Email Address:</label>
            <input
              type="email"
              className="form-control"
              onChange={(e) => setEmail_Adress(e.target.value)}
              value={email_adress}
              required
            />
          </div>

          <div className="mb-3">
            <label>Password:</label>
            <input
              type="password"
              className="form-control"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              required
            />
          </div>

          <button type="submit" className="btn btn-primary w-100">
            Login
          </button>

          <Link to="/register" style={{ marginTop: "10px", textAlign: "center", display: "block" }}>
            Don't have an account? Register here
          </Link>

          {error && <div className="alert alert-danger mt-3">{error}</div>}
        </form>
      </div>

      <FooterComponent />
    </div>
  );
};

export default Login;
